<template>
  <div class="OlderMenu">
    <div class="menu">
      <div class="olderInfo item">
        <div>{{$t('User.Orders.Pages.RecentOrder')}}<i class="iconfont icon-jiantou-copy-copy"></i></div>
        <div class="item">{{$t('Common.NavTitle.OrderDetail')}}</div>
      </div>
      <div class="item user">{{$t('Cart.Checkout.ConsigneeInfo')}}</div>
      <div class="item rightItem">{{$t('Home.Pages.Price')}}</div>
      <div
        class="item rightItem allState"
        @mouseover="isShowAllState = true"
        @mouseout="isShowAllState = false"
      >
        {{ stateArr[parseInt(chooseState)]
        }}<i class="iconfont icon-jiantou-copy-copy"></i>
        <drop-down :isvisible="isShowAllState">
          <ul class="stateList">
            <li
              v-for="(item, index) in stateArr"
              :key="item"
              :class="{ active: parseInt(chooseState) == index }"
              @click="chooseNav(index)"
            >
              {{ item }}
            </li>
          </ul>
        </drop-down>
      </div>
      <div class="item rightItem">{{$t('Common.Button.Operation')}}</div>
    </div>
  </div>
</template>
<script>
import DropDown from "common/dropDown/DropDown";
export default {
  data() {
    return {
      // 全部状态是否显示
      isShowAllState: false,

      //状态数组
      stateArr: [ this.$t('User.Orders.Pages.AllOrders'),
      this.$t('User.Orders.Pages.PendingPayment'), 
      this.$t('User.Orders.Pages.Delivering'),
       this.$t('App.Rcom.Pages.FINISHED'), 
       this.$t('User.Orders.Pages.Canceled'), 
       ],

      //当前选择的
      choose: 0,
    };
  },
  model: {
    prop: "chooseState",
    event: "change",
  },
  created() {},
  computed: {
    //解决顶部和下拉索引大于2不匹配问题
    showActive: {
      get() {
        if (this.chooseState <= 2) {
          return this.chooseState;
        } else {
        }
      },
      set(index) {
        if (index > 2) {
          index = 0;
        }
        this.$emit("change", index);
      },
    },
  },
  components: {
    DropDown,
  },
  methods: {
    chooseNav(index) {
      this.$emit("change", index);
    },
  },
  props: {
    chooseState: null,
  },
};
</script>
<style lang='scss' scoped>

.OlderMenu {
  margin-top: 1rem;
  background-color: #f5f5f5;
  padding: 0.875rem 1rem;
  font-size: 0.75rem;
  .menu {
    // padding-right: 1.5rem;
    align-items: center;
    display: flex;
    .item {
      cursor: pointer;
      color: var(--text-color-default);
      i {
        margin-left: 0.3rem;
      }
    }

    .allState {
      position: relative;
      .stateList {
        padding: 0.5rem;
        li {
          padding: 0.25rem 0;
          list-style: none;
          &:hover {
            color: var(--red-color);
          }
        }

        .active {
          color: var(--red-color);
        }
      }
    }

    .olderInfo {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 55%;
      justify-content: space-around;
    }

    .user {
      text-align: center;
      max-width: 9%;
      width: 100%;
    }

    .rightItem {
      max-width: 12%;
      width: 100%;
      text-align: center;
    }
  }
}
</style>