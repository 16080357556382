<template>
  <div class="MemberOrder">
    <older-top-tools
      v-model="topState"
      :orderList="orderList"
    ></older-top-tools>
    <older-menu v-model="menuState"></older-menu>

    <div class="list">
      <older-list
        ref="olderList"
        :orderList="orderList"
        @changePage="changePage"
      ></older-list>
    </div>
  </div>
</template>
<script>
import { OlderTopTools, OlderMenu, OlderList } from "./memberOrderChild";
import User from "@/model/User.js";
export default {
  name: "MemberOrder",
  data() {
    return {
      // 当前选择的状态 全部订单 待发货。。。
      topState: 0,
      menuState: 0,

      //订单列表
      orderList: {},

      //当前列表的页码
      page: 1,

      //当前的列表status
      status: "all",

      //当前是否正在加载中
      isLoading: true,

      //是否是第一次载入
      isbegain: true,
    };
  },

  watch: {
    topState(index) {
      if (typeof index == "string") {
        return;
      }
      if (index <= 2) {
        this.menuState = index.toString();
      }
    },
    menuState(index) {
      this.getNewList(index);
      if (typeof index == "string") {
        return;
      }
      if (index <= 2) {
        this.topState = index.toString();
      }
      if (index > 2) {
        this.topState = "0";
      }
    },
  },

  methods: {
    //重新请求订单列表数据
    getNewList(index) {
      index = parseInt(index);
      let status;
      switch (index) {
        case 0:
          status = "all";
          break;
        case 1:
          status = "await_pay";
          break;
        case 2:
          status = "shipped";
          break;
        case 3:
          status = "confirmed";
          break;
        case 4:
          status = "canceled";
          break;
      }
      this.$router.replace({
        path: "/member/orders",
        query: {
          status,
        },
      });
      this.changeList(status);
    },

    //第一次进入时根据url渲染选中状态
    randerChoose() {
      let status = this.$route.query.status;
      if(!status || status == 'all'){
        return this.isbegain = false
      }
      let index = 0;
      switch (status) {
        case "all":
          index = 0;
          break;
        case "await_pay":
          index = 1;
          break;
        case "shipped":
          index = 2;
          break;
        case "confirmed":
          index = 3;
          break;
        case "canceled":
          index = 4;
          break;
      }

      this.menuState = index
    },

    //请求订单列表数据
    getOrderListData() {
      let loading;
      this.$nextTick(() => {
        loading = this.$loading(this.$refs.olderList.$el);
      });
      this.isLoading = true;
      User.Order.List({
        data: {
          page: this.page,
          status: this.status,
          limit: 10,
        },
        succ: (res) => {
          this.orderList = res;
          this.isLoading = false;
          this.isbegain = false
          loading.close();
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
          this.isLoading = false;
          loading.close();
        },
        
      });
    },

    //获取当前组件距离顶部的距离，用户页码跳转时的置顶
    getHeight() {
      let headerH = document
        .querySelector("#app")
        .querySelector(".header").scrollHeight;
      return headerH;
    },

    //改变索引后修改订单列表数据
    changeList(status) {
      // 更改导航的索引
      this.$refs.olderList.resetPage();
      this.page = 1;
      this.status = status;
      this.getOrderListData();
    },

    // 修改页码
    changePage(page) {
      this.page = page;
      scrollTo(0, this.getHeight());
      this.getOrderListData();
    },


  },
  created() {
    this.randerChoose()
    if(!this.isbegain){
      this.getOrderListData();
    }
  },
  components: {
    OlderTopTools,
    OlderMenu,
    OlderList,
  },
  props: {},
};
</script>
<style lang='scss' scoped>

.MemberOrder {
  padding: 1rem;
  background-color: white;
  box-sizing: border-box;
}

.list {
  position: relative;
}
</style>