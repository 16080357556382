<template>
  <div class="list">
    <older-item
      v-for="item in orderList.order_list"
      :key="item.order_id"
      :item="item"
    ></older-item>

      <empty :decripe="type"  v-if="orderList.order_list && orderList.order_list.length == 0 && !$parent.isLoading"></empty>

    <div class="page">
      <pagination
        :totalPage="!!!orderList.paginated ? 1 : orderList.paginated.pages"
        v-model="currentPage"
        @changePage="changePage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import Three from "common/layout/Three";
import OlderItem from "./olderItem/OlderItem.vue";
import Pagination from "common/pagination/Pagination";
import Empty from "common/empty/Empty";
import Loading from "common/loading/type/Loading";
export default {
  name: "OlderList",
  data() {
    return {
      currentPage: 1,
    };
  },
  created() {},
  components: {
    Three,
    OlderItem,
    Pagination,
    Empty,
    Loading,
  },
  computed: {
    type() {
      let type = this.$parent.status;
      let res;
      switch (type) {
        case "all":
          res = this.$t('User.Orders.Empty.NoOrder');
          break;
        case "await_pay":
          res = this.$t('User.Orders.Empty.NoAwaitPay');
          break;
        case "shipped":
          res = this.$t('User.Orders.Empty.NoShipping');
          break;
        case "confirmed":
          res = this.$t('User.Orders.Empty.NoFinish');
          break;
        case "canceled":
          res =  this.$t('User.Orders.Empty.NoCancel');
          break;
      }

      return res;
    },


  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },

    resetPage() {
      this.currentPage = 1;
    },
  },
  props: {
    orderList: {
      type: Object,
    },
  },
};
</script>
<style lang='scss' scoped>

.list {
  box-sizing: border-box;
  margin-top: 1rem;
  min-height: 400px;

  .page {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }
}
</style>