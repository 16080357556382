<template>
  <div class="three" :style="{ padding: padding ,alignItems:mode}">
    <div class="left" :style="{ width: allwidth[0] }">
        <slot name="left"></slot>
    </div>
    <div class="center" :style="{ width: allwidth[1] }">
          <slot name="center"></slot>
    </div>
    <div class="right" :style="{ width: allwidth[2] }">
          <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Three",
  data() {
    return {};
  },
  created() {},
  props: {
    padding: String,
    allwidth:Array,
    mode:{
      type:String,
      default:'center'
    }
  },
};
</script>
<style lang='scss' scoped>
.three{
    display: flex;
    box-sizing: border-box;
    .left,.center,.right{
        box-sizing: border-box;
    }
}
</style>