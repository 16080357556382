<template>
  <div class="pagination">
    <div class="btn" :class="{ disabled: currentPage == 1 }" @click="go(-1)">
      <!-- <span>上一页</span> -->
      <span>{{$t('Common.Button.Back')}}</span>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in pagination.PageArr"
        :key="item + index"
      >
        <page-item
          :page="item"
          @changePage="changePage"
          :active="item == currentPage"
        ></page-item>
      </div>
    </div>
    <div
      class="btn"
      :class="{ disabled: currentPage == totalPage }"
      @click="go(1)"
    >
      <!-- <span>下一页</span> -->
      <span>{{$t('Common.Button.Next')}}</span>
    </div>
    <div class="allPage">
      <!-- 共 -->
      {{$t('Common.Button.InAll')}}<strong>{{ totalPage }}</strong
      >
      <!-- 页 -->
       {{$t('Common.Button.Page')}}
    </div>

    <div class="gopage">
      <!-- <span>到第</span> -->
      <span>  {{$t('Common.Button.Go')}}</span>
      <input type="text" v-on:keyup.enter="tip" v-model="tipPage" />
      <!-- <span>页</span> -->
      <span>{{$t('Common.Button.Page')}}</span>
      <div class="btn" @click="tip">
        <!-- <span>确定</span> -->
        <span>{{$t('Common.Confirm.Yes')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import PageItem from "./PageItem";
import Paging from "./pagination_class.js";
export default {
  name: "Pagination",
  data() {
    return {
      // 选择器对象
      pagination: {},

      //跳转页码
      tipPage: "",
    };
  },
  created() {},
  watch: {
    totalPage:{
        handler(){
          this.init();
        },
        immediate: true,
    },

    currentPage() {
      setTimeout(()=>{
        this.pagination.setPage(this.currentPage);
      },700)
    },
  },
  methods: {
    init() {
      this.pagination = new Paging({
        allPageNum: this.totalPage<=0 ? 1 : this.totalPage,
      });
    },

    //修改页码
    changePage(page) {
      page = parseInt(page);
      if (page == this.currentPage) return;
      this.$emit("change", page);
      this.$emit("changePage", page);
    },

    //上一页和下一页
    go(page) {
      if (page == -1) {
        if (this.currentPage == 1) {
          return;
        } else {
          this.changePage(this.currentPage - 1);
        }
      }
      if (page == 1) {
        if (this.currentPage == this.totalPage) {
          return;
        } else {
          this.changePage(this.currentPage + 1);
        }
      }
    },

    //输入页码跳转
    tip() {
      if (this.tipPage == "") {
        return;
      }
      this.tipPage = parseInt(this.tipPage);
      if (this.tipPage.toString() == "NaN") {
        this.tipPage = "";
        return;
      }
      if (this.tipPage > this.totalPage) {
        this.changePage(this.totalPage);
        this.tipPage = "";
        return;
      }

      if (this.tipPage <= 0) {
        this.changePage(0);
        this.tipPage = "";
        return;
      }

      this.changePage(this.tipPage);
      this.tipPage = "";
    },
  },
  computed: {},
  components: {
    PageItem,
  },
  model: {
    prop: "currentPage",
    event: "change",
  },
  props: {
    //总页数
    totalPage: {
      type: Number,
      default: 0,
    },
    //当前的页码
    currentPage: {
      type: Number,
      default: 1,
    },
  },
};
</script>
<style lang='scss' scoped>

.pagination {
  display: flex;
  .btn {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    border: 1px solid #e3e4e6;
    box-sizing: border-box;
    margin-right: 0.5rem;
    cursor: pointer;
    span {
      font-size: 0.875rem;
      color: #6e6e73;
    }
  }

  .disabled {
    span {
      color: #cccccc;
    }
  }
  .allPage {
    height: 2rem;
    color: #6e6e73;
    font-size: 0.875rem;
    line-height: 2rem;
    margin: 0 0.5rem;
    strong {
      margin: 0 0.2rem;
    }
  }

  .gopage {
    color: #6e6e73;
    font-size: 0.875rem;
    display: flex;
    height: 2rem;
    align-items: center;
    input {
      padding: 0 0.2rem;
      box-sizing: border-box;
      width: 2rem;
      outline: none;
      height: 100%;
      border: 1px solid #e3e4e6;
      margin: 0 0.3rem;
    }
    .btn {
      background-color: #f7f7f7;
      box-sizing: border-box;
      padding: 0 0.5rem;
      margin-left: 0.5rem;
    }
  }
  .list {
    display: flex;
    .item {
      margin-right: 0.5rem;
    }
  }
}
</style>