<template>
  <div class="nav" :style="setStyle">
    <div class="name font_hide" :title="name">
      {{ name }}
    </div>
    <div class="num" v-if="num != 0">{{ setNum }}</div>
  </div>
</template>
<script>
export default {
  name: "FontNavItem",
  data() {
    return {};
  },
  created() {},
  computed: {
    setStyle() {
      if (this.active) {
        return {
          color: this.colorState.activity,
          borderBottom: "1px solid" + this.colorState.activity,
        };
      } else {
        return {
          color: this.colorState.inactivity,
        };
      }
    },

    // 当数字大于99时应当变更相应样式
    setNum() {
      if (this.num > 99) {
        return "99+";
      }
      return this.num;
    },
  },
  props: {
    active: Boolean,
    name: String,
    num: {
      type: Number,
      default: 0,
    },
    colorState: {
      type: Object,
      default: () => {
        return {
          inactivity: "#202435",
          activity: "#d51243",
        };
      },
    },

  },
};
</script>
<style lang='scss' scoped>

.nav {
  position: relative;
  cursor: pointer;
  max-width: 60px;
  .num {
    position: absolute;
    width: 1.5rem;
    height: 1rem;
    background-color: #d51243;
    color: white;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 0.75rem;
    top: -0.5rem;
    right: -1.5rem;
  }
    border-bottom: 1px solid transparent;
}

.name {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.5;
}
</style>