// 创建分页

class Paging {
    constructor(config) {
      // 总数据量
      this.dataNum = config.dataNum
      // 每页多少个数据
      this.pageDataNum = config.pageDataNum
      //总页数
      this.allPageNum = config.allPageNum || Math.ceil(this.dataNum / this.pageDataNum)
      //当前展示的页码最大长度
      this.maxPageBtnNum = config.maxPageBtnNum || 7
      //当前的页码
      this.page = 1
      //生成的页码展示数组
      this.PageArr = []
      this.init()
    }
    init() {
      this.randerDefault()
    }
    // 渲染默认数组
    randerDefault() {
      //页数不足最大页码时
      if (this.allPageNum <= this.maxPageBtnNum) {
        for (let i = 1; i <= this.allPageNum; i++) {
          this.PageArr.push(i)
        }
      } else {
        //页数大于最大页码时
        for (let i = 1; i <= this.maxPageBtnNum - 1; i++) {
          this.PageArr.push(i)
        }
        this.PageArr.push('...')
        this.PageArr.push(this.allPageNum)
      }
    }
  
    //设置页码后改变数组
    setPage(page) {
      page = parseInt(page)
      this.page = page
      // 第一种情况
      if (this.allPageNum <= this.maxPageBtnNum) {
        // 数组不变
        return this.PageArr
      }
      // 第二种情况
      if (this.page <= Math.ceil(this.maxPageBtnNum / 2)) {
        this.PageArr = []
        this.randerDefault()
        // 数组不变
        return this.PageArr
      }
      //第三种情况
      if (this.page > Math.ceil(this.maxPageBtnNum / 2) && this.page < this.allPageNum - Math.floor(this.maxPageBtnNum / 2)) {
        this.PageArr = []
        this.PageArr.push(1, '...', '...', this.allPageNum)
        let centerArr = []
        let half = Math.floor((this.maxPageBtnNum - 2) / 2)
        for (let i = 0; i < half; i++) {
          centerArr.push(this.page - half + i)
        }
        for (let i = 0; i <= half; i++) {
          centerArr.push(this.page + i)
        }
        this.PageArr.splice(2, 0, ...centerArr)
      }
      //第四种情况
      if (this.page >= this.allPageNum - Math.floor(this.maxPageBtnNum / 2)) {
        this.PageArr = []
        this.PageArr.push(1, '...')
        let needaddArr = []
        for (let i = 0; i < this.maxPageBtnNum - 1; i++) {
          needaddArr.push(this.allPageNum - i)
        }
        this.PageArr.push(...needaddArr.reverse())
      }
      return this.PageArr
    }
  }
  
  export default Paging
  
  