var U = require("../utils/ajax.js");

var Create = function (opt) {
 return U.ajax({
    service: 'order.create',
    data: {
      addr_id: opt.data.addr_id,
      shipping_id: opt.data.shipping_id,
      selgoods: opt.data.selgoods,
      payment: opt.data.payment,
      source: opt.data.source,
      memo: opt.data.memo,
      is_pintuan: opt.data.is_pintuan,
      pintuan_id: opt.data.pintuan_id,
      pintuan_num: opt.data.pintuan_num,
      free_pintuan_id: opt.data.free_pintuan_id,
      originator_free: opt.data.originator_free,
      formId: opt.data.formId,
      md5_cart: opt.data.md5_cart,
	  extend:opt.data.extend,
	  outofstoredo:opt.data.outofstoredo,
	  is_fastbuy:opt.data.is_fastbuy,
	  uc_id:opt.data.uc_id,//使用的优惠券id,
	  use_balance:opt.data.use_balance,//使用的余额抵扣
	  use_point:opt.data.use_point,//使用积分抵扣
	  ship_day:opt.data.ship_day
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this:opt.context_this
  });
};

var CreateBudan = function (opt) {
 return U.ajax({
    service: 'order.createbudan',
    data: {
     
      selgoods: opt.data.selgoods,
	  budan_order_id:opt.data.budan_order_id,
	  extend:opt.data.extend,
	  is_fastbuy:opt.data.is_fastbuy,
	  uc_id:opt.data.uc_id,//使用的优惠券id,
	  use_balance:opt.data.use_balance,//使用的余额抵扣
	  use_point:opt.data.use_point,//使用积分抵扣
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this:opt.context_this
  });
};

var Cancel = function (opt) {
 return U.ajax({
    service: 'order.cancel',
    data: {
      order_id: opt.data.order_id
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this:opt.context_this
  });
};

var Confirm = function (opt) {
 return U.ajax({
    service: 'order.confirm',
    data: {
      order_id: opt.data.order_id
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this:opt.context_this
  });
};

var Logi = function (opt) {
 return U.ajax({
    service: 'order.logiquery',
    data: {
      order_id: opt.data.order_id
    },
    succ: function (res, all) {
      opt.succ(res);
    },
    fail: function (res, all) {
      // console.log('logiquery', res);
	  opt.fail(res, all);
      // U.showtip(res.msg, '', 'none');
    },
    context_this:opt.context_this
  });
};

module.exports = {
  Create: Create,
  CreateBudan,
  Cancel: Cancel,
  Confirm: Confirm,
  Logi: Logi
};