<template>
  <div class="pageitem">
    <div class="item" :class="{ active: active }" @click="changePage(page)">
      <span >{{ page }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    changePage(page) {
      if (typeof page != "number") {
        return;
      }
      this.$emit("changePage", page);
    },
  },
  props: {
    page: [Number, String],
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang='scss' scoped>

.pageitem {
  .item {
    width: 2rem;
    height: 2rem;
    border: 1px solid #e3e4e6;
         background-color:#f7f7f7;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      color: #6e6e73;
    }
  }
    .active {
      background-color: white;
     span{
        color: #dc3545;
     }
    }
}
</style>