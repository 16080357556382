<template>
  <div class="orderTop">
    <div class="left">
      <div class="navtop">
        <font-nav-item
          v-for="(item, index) in navlist"
          :key="item.name"
          :active="index == parseInt(chooseState)"
          :name="item.name"
          :num="item.num"
          @click.native="chooseNav(index)"
        >
        </font-nav-item>
      </div>

      <div class="rightTool">
        <div class="oftenGood">{{$t('User.Orders.Crumbs.MyFavorite')}}</div>
        <div class="oldOrder">{{$t('User.Orders.Crumbs.OrderRecycling')}}</div>
      </div>
    </div>

    <div class="right">
      <has-icon-input
        :placeholder="$t('User.Orders.Pages.OrderNo')"
        :height="30"
        :issetRighticonBtn="true"
        v-model="search"
      >
        <template v-slot:rightBtn>
          <div style="padding: 0 0.5rem">
            <i class="iconfont icon-seach"></i>
          </div>
        </template>
      </has-icon-input>
      <div class="more">
        {{$t('User.Orders.Pages.Advanced')}}
        <i class="iconfont icon-jiantou-copy-copy"></i>
      </div>
    </div>
  </div>
</template>
<script>
import FontNavItem from "common/navigation/FontNavItem";
import HasIconInput from "common/input/HasIconInput";
import DropDown from "common/dropDown/DropDown";
export default {
  name: "OlderTopTools",
  data() {
    return {
      navlist: [
        { name: this.$t('User.Orders.Pages.AllOrders'), num: 0 },
        { name: this.$t('User.Orders.Pages.PendingPayment'), num: 0 },
        { name: this.$t('User.Orders.Pages.Delivering'), num: 0 },
        { name:  this.$t('User.Orders.Pages.PendingEvaluate'), num: 0 },
      ],
      search: "",
    };
  },

  watch: {
    "orderList.paginated"(val) {
      this.navlist.forEach((item, index) => {
        if (index == 0) {
          if (this.orderList.paginated) {
            let total = 0
            for(let item in this.orderList.status_num){
              total += this.orderList.status_num[item]
            }
              item.num = total
          }
        }
        if (index == 1) {
          if (this.orderList.paginated) {
            item.num = this.orderList.status_num.await_pay;
          }
        }
        if (index == 2) {
          if (this.orderList.paginated) {
            item.num = this.orderList.status_num.shipped;
          }
        }
        if (index == 3) {
          if (this.orderList.paginated) {
            item.num = this.orderList.status_num.confirmed;
          }
        }
      });
    },
  },

  model: {
    prop: "chooseState",
    event: "change",
  },
  created() {
    

  },
  components: {
    FontNavItem,
    HasIconInput,
    DropDown,
  },
  props: {
    chooseState: null,
    orderList: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    chooseNav(index) {
      this.$emit("change", index);
    },
  },
};
</script>
<style lang='scss' scoped>

.orderTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    .navtop {
      width: 100%;
      max-width: 55%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .rightTool {
      margin-left:1rem;
      display: flex;
      .oftenGood {
        font-weight: 600;
        color: var(--text-color-title);
        font-size: 0.75rem;
        padding: 0 1rem;
        border-left: 1px solid var(--border-color-default);
        border-right: 1px solid var(--border-color-default);
        text-align: center;
      }

      .oldOrder {
        font-size: 0.75rem;
        padding: 0 1.5rem;
        text-align: center;
        color: var(--text-color-default);
      }
    }
  }

  .right {
    display: flex;
    .more {
      cursor: pointer;
      height: 30px;
      font-size: 0.75rem;
      line-height: 30px;
      color: white;
      padding: 0 0.4rem;
      background-color: var(--red-color);
    }
  }
}
</style>