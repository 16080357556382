<template>
  <div class="item">
    <div class="title">
      <div class="left">
        <span class="time">{{ item.createtime_text }}</span>
        <span class="order"
          >{{$t('User.Orders.Pages.OrderNo')}}：<span>{{ item.order_id }}</span></span
        >
        <!-- <span class="shopName"
          >凤凰新华书店旗舰店<i class="iconfont icon-daipingjia01"></i
        ></span> -->
      </div>
      <div class="right">
        <span class="mobile"
          ><i class="iconfont icon-dianhua"></i>07923969602</span
        >
      </div>
    </div>

    <div class="detil">
      <div class="goodInfo">
        <div
          class="goodItem"
          v-for="goodItem in item.goods_list"
          :key="goodItem.goods_id"
        >
          <three
            padding="0rem 1rem 1rem 1rem"
            :allwidth="['60%', '15%', '25%']"
            mode="flex-start"
          >
            <template v-slot:left>
              <div class="left">
                <div class="img">
                  <img @click="goGoodDetil(goodItem.product_id)" v-lazy="goodItem.image" alt="" />
                </div>
                <div class="font">
                  <p class="name font_two_hide" @click="goGoodDetil(goodItem.product_id)">
                    {{ $cl('name',goodItem,$i18n.locale)}}
                  </p>
                  <p class="property font_hide">
                    <span class="kd_class">{{ goodItem.price_text}}</span>
                  </p>
                </div>
              </div>
            </template>
            <template v-slot:center>
              <div class="center">x{{ goodItem.num }}</div>
            </template>
            <template v-slot:right>
              <div class="right">
                <span>{{$t('User.Orders.Pages.ApplySales')}}</span>
              </div>
            </template>
          </three>
        </div>
      </div>

      <div class="user">
        <span><i class="iconfont icon-user"></i>{{ item.ship_name }}</span>
      </div>

      <!-- 价格信息 -->
      <div class="priceInfo rightInfo">
        <!-- 待支付 -->
        <div class="await_pay" v-if="item.status == 'AWAIT_PAY'">
          <p style="margin-bottom: 0.5rem">{{$t('User.Orders.Pages.AccountPayable')}}</p>
          <p class="price">{{ item.total_amount_text }}</p>
        </div>

        <!-- 待发货 -->
        <div class="await_ship" v-if="item.status == 'AWAIT_SHIP'">
          <p class="price">{{ item.total_amount_text }}</p>
        </div>

        <!-- 待收货 -->
        <div class="shipped" v-if="item.status == 'SHIPPED'">
          <p class="price">{{ item.total_amount_text }}</p>
        </div>

        <!-- 已完成 -->
        <div class="finished" v-if="item.status == 'CONFIRMED'">
          <p class="price">{{ item.total_amount_text }}</p>
        </div>

        <!-- 已取消 -->
        <div class="canceld" v-if="item.status == '已取消' || item.status == 'CANCELED' || item.status== 'Canceled'">
          <p class="price">{{ item.total_amount_text }}</p>
        </div>

        <p class="method">{{$t('User.Orders.Pages.OnlinePayment')}}</p>
      </div>

      <div class="orderInfo rightInfo">
        <!-- 待支付 -->
        <div class="await_pay" v-if="item.status == 'AWAIT_PAY'">
          <p>{{$t('User.Orders.Pages.PendingPayment')}}</p>
        </div>

        <!-- 待发货 -->
        <div class="await_ship" v-if="item.status == 'AWAIT_SHIP'">
          <p>{{$t('User.Orders.Pages.PendingShipment')}}</p>
        </div>

        <!-- 待收货 -->
        <div class="shipped" v-if="item.status == 'SHIPPED'">
          <p>{{$t('User.Orders.Pages.Delivering')}}</p>
        </div>

        <!-- 已完成 -->
        <div class="finished" v-if="item.status == 'CONFIRMED'">
          <p>{{$t('App.Rcom.Pages.FINISHED')}}</p>
        </div>

        <!-- 已取消 -->
        <div class="canceld" v-if="item.status == '已取消' || item.status == 'CANCELED' || item.status== 'Canceled'">
          <p>{{$t('User.Orders.Pages.Canceled')}}</p>
        </div>



        <p class="stateName" @click="goOrderDetil(item.order_id)">{{$t('Common.NavTitle.OrderDetail')}}</p>
      </div>

      <div class="otherTool rightInfo">
        <!-- 待支付 -->
        <div class="await_pay" v-if="item.status == 'AWAIT_PAY'">
          <!-- <p style="margin-bottom: 0.5rem">
            <i class="iconfont icon-shijian"></i>剩余{{ time.h }}时{{
              time.m
            }}分
          </p> -->
          <!-- <timer deadline="2021-12-19 02:37:32" :time.sync="time"></timer> -->
          <div class="payBtn" @click="pay(item.order_id)">{{$t('Paycenter.Button.GotoPay')}}</div>
          <p class="cancelOrder" @click="cancelOrder(item.order_id)">
          {{$t('User.Orders.Button.CancelOrder')}}
          </p>
          <p class="lookInvoice"> {{$t('User.Orders.Button.CheckInvoice')}}</p>
        </div>

        <!-- 待发货 -->
        <div class="await_ship" v-if="item.status == 'AWAIT_SHIP'">
          <div class="refundBtn">{{$t('User.Orders.Button.ApplicationDrawback')}}</div>
        </div>

        <!-- 待收货 -->
        <div class="shipped" v-if="item.status == 'SHIPPED'">
          <div class="refundBtn btn" @click="refundApply">{{$t('User.Orders.Button.ApplicationDrawback')}}</div>
          <div class="comfirmBtn btn font_hide" @click="comfirmGet(item.order_id)">
           {{$t('User.Orders.Button.ConfirmReceipt')}}
          </div>
          <div class="logisticsBtn btn">{{$t('User.Orders.Pages.Logi')}}</div>
        </div>

        <!-- 已完成 -->
        <div class="finished" v-if="item.status == 'CONFIRMED'">
          <div class="btn" @click="buy">
            <i class="iconfont icon-shandian"></i><span>{{$t('Goods.Pages.BuyNow')}}</span>
          </div>
        </div>

        <!-- 已取消 -->
        <div class="canceld" v-if="item.status == '已取消' || item.status == 'CANCELED' || item.status == 'Canceled'">
          <div class="btn" @click="buy">
            <i class="iconfont icon-shandian"></i><span>{{$t('Goods.Pages.BuyNow')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Three from "common/layout/Three";
import Timer from "common/time/Timer";
import Order from "@/model/Order.js";

export default {
  name: "OlderList",
  data() {
    return {
      time: {},
    };
  },
  created() {},
  components: {
    Three,
    Timer,
  },
  methods: {
    //取消订单
    cancelOrder(order_id) {
      this.$confirm("danger", this.$t('Common.Confirm.Title'), this.$t('User.Orders.Msg.CancelOrder'),true,null,this)
        .then((ele) => {
          Order.Cancel({
            data: {
              order_id,
            },
            succ: (res, all) => {
              ele.close()
              this.$parent.$parent.getOrderListData();
              this.$message("success", all.msg);
            },
            fail: (res, all) => {
              ele.close()
               this.$parent.$parent.getOrderListData();
              this.$message("error", all.msg);
            },
            
          });
        })
        .catch(() => {});
    },

    // 点击立即购买
    buy() {
      console.log("立即购买");
    },

    //点击订单详情
    goOrderDetil(order_id){
      this.$router.push({
        path:'/ordertail/index',
        query:{
          order_id
        }
      })

    },

     //跳转到商品详情页
    goGoodDetil(product_id){
      this.$router.push({
        path:'/product/index',
        query:{
          product_id
        }
      })

    },


    //点击确认收货
    comfirmGet(order_id) {
      this.$confirm("danger",this.$t('Common.Confirm.Title'), this.$t('User.Orders.Msg.ConfirmGetGoods'),true,null,this)
        .then((ele) => {
          Order.Confirm({
            data: { order_id },
            succ: (res, all) => {
              ele.close()
              this.$parent.$parent.getOrderListData();
              this.$message("success", all.msg);
            },
            fail: (res, all) => {
              ele.close()
              this.$message("error", all.msg);
            },
            
          });
        })
        .catch(() => {});
    },

    // 申请退款
    refundApply(){
      let order_id = this.item.order_id

      console.log(order_id)
    },

    // 点击付款按钮
    pay(order_id) {
      this.$router.push({
        path: "/paycenter/index",
        query: {
          order_id: order_id,
        },
      });
    },
  },
  props: {
    //订单信息
    item: {
      type: Object,
    },
  },
};
</script>
<style lang='scss' scoped>

@mixin btn {
  height: 1.5rem;
  width: 70%;
  padding:0 0.2rem;
  box-sizing: border-box;
  color: var(--red-color);
  cursor: pointer;
  box-sizing: border-box;
  margin: 0.3rem auto;
  line-height: 1.5rem;
  border: 1px solid var(--red-color);
  &:hover {
    opacity: 0.9;
  }
}

.item {
  border: 1px solid var(--border-color-default);
  margin-top: 1rem;
  > .title {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    .left {
      width: 55%;
      padding: 0.5rem 1.5rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      > .time {
        color: var(--text-color-default);
      }
      > .order {
        color: var(--text-color-default);
        > span {
          color: var(--text-color-title);
        }
      }
      > .shopName {
        color: var(--text-color-title);
        i {
          font-size: 0.875rem;
          margin-left: 0.5rem;
          color: var(--minor-color);
        }
      }
    }

    .right {
      width: 45%;
      padding: 0.5rem 1.5rem;
      box-sizing: border-box;
      text-align: center;
      > .mobile {
        > i {
          font-size: 0.875rem;
          margin-right: 0.5rem;
        }
        color: var(--text-color-default);
      }
    }
  }

  > .detil {
    display: flex;
    > .goodInfo {
      padding: 0.875rem 0 0 0rem;
      box-sizing: border-box;
      width: 55%;
      font-size: 0.75rem;
      .goodItem:not(.goodItem:last-child) {
        border-bottom: 1px solid var(--border-color-default);
      }
      .goodItem:not(.goodItem:first-child) {
        margin-top: 1rem;
      }

      .left {
        display: flex;

        .img {
          flex: none;
          width: 4rem;
          // height: 4rem;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
        .font {
          flex: none;
          width: calc(100% - 4rem);
          padding-left: 0.875rem;
          box-sizing: border-box;
          .name {
            color: var(--text-color-title);
            cursor: pointer;
            &:hover{
              color: var(--red-color);
            }
          }

          .property {
            line-height: 2;
            color: var(--text-color-default);
          }
        }
      }

      .center,
      .right {
        color: var(--text-color-default);
        text-align: center;
      }
    }

    .rightInfo {
      width: 12%;
      box-sizing: border-box;
      border-left: 1px solid var(--border-color-default);
      text-align: center;
      padding: 0.875rem 0;
      font-size: 0.75rem;
    }

    > .user {
      width: 9%;
      border-left: 1px solid var(--border-color-default);
      padding: 0.875rem 0;
      font-size: 0.75rem;
      text-align: center;
      box-sizing: border-box;
      color: var(--text-color-title);
    }

    > .priceInfo {
      color: var(--text-color-default);
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      .await_pay {
        color: var(--text-color-title);
        .price {
          padding-bottom: 0.5rem;
        }
        border-bottom: 1px solid var(--border-color-default);
      }

      .await_ship {
        color: var(--text-color-default);
        .price {
          padding-bottom: 0.5rem;
        }
        border-bottom: 1px solid var(--border-color-default);
      }
      .shipped {
        color: var(--text-color-default);
        .price {
          padding-bottom: 0.5rem;
        }
        border-bottom: 1px solid var(--border-color-default);
      }

      .finished {
        color: var(--text-color-default);
        .price {
          padding-bottom: 0.5rem;
        }
        border-bottom: 1px solid var(--border-color-default);
      }

      .method {
        line-height: 2;
      }
    }

    > .orderInfo {
      .await_pay {
        color: orange;
        margin-bottom: 0.5rem;
      }

      .await_ship {
        color: var(--text-color-default);
        margin-bottom: 0.5rem;
      }
      .shipped {
        color: var(--text-color-default);
        margin-bottom: 0.5rem;
      }

      .finished {
        color: var(--text-color-default);
        margin-bottom: 0.5rem;
      }

      .canceld {
        color: var(--text-color-default);
        margin-bottom: 0.5rem;
      }

      .stateName {
        color: var(--text-color-title);
        cursor: pointer;
        &:hover {
          color: var(--red-color);
        }
        line-height: 2;
      }
    }

    > .otherTool {
      .await_pay {
        color: var(--text-color-default);
        .payBtn {
          @include btn;
        }
        .cancelOrder {
          color: var(--text-color-title);
          line-height: 2.5;
          cursor: pointer;
        }
        .lookInvoice {
          cursor: pointer;
          color: var(--text-color-default);
        }
      }

      .await_ship {
        .refundBtn {
          @include btn;
        }
      }

      .shipped {
        .refundBtn,
        .comfirmBtn,
        .logisticsBtn {
          @include btn;
        }
      }

      .finished{
         .btn {
          height: 1.75rem;
          width: 80%;
          cursor: pointer;
          box-sizing: border-box;
          margin: 0.3rem auto;
          line-height: 1.75rem;
          background-color: #f5f5f5;
          border: 1px solid #e9e9e9;
          &:hover {
            opacity: 0.9;
          }
        }
      }

      .canceld {
        .btn {
          height: 1.75rem;
          width: 80%;
          cursor: pointer;
          box-sizing: border-box;
          margin: 0.3rem auto;
          line-height: 1.75rem;
          background-color: #f5f5f5;
          border: 1px solid #e9e9e9;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
</style>