<template>
<div class="empty" :style="{padding}">

    <!-- 内容为空时显示 -->
    <div class="img"  v-if="!isloading">
        <slot name="img">

        </slot>
    </div>

    <div class="decripe" v-if="!isloading">
        <p :style=setStyle>{{decripe}}</p>
    </div>
    <div class="loading" v-if="isloading">
        <loading></loading>
    </div>
    <div class="button"  v-if="!isloading">
        <slot name="button"></slot>
    </div>
</div>
</template>
<script>
import Loading from 'common/loading/type/Loading'
export default {
name:'Empty',
data(){
return {
}
},
components:{
    Loading
},
props:{
    decripe:{
        type:String,
        default:'这是一段描述文字'
    },

    // 类型 loading  empty  error  
    type:{
        type:String,
        default:'loading'
    },

    //设置描述文字的样式
    setStyle:{
        type:Object,
    },

    isloading:{
        type:Boolean,
        default:false
    },
    // 内边距信息
    padding:{
        type:String,
        default:'10rem 0'
    }
},
created(){
},
}
</script>
<style lang='scss' scoped>
.empty{
    width:100%;
    // padding:10rem 0;
    .img{
        width:18%;
        margin:0 auto;
        display: flex;
        justify-content: center;
        
    }

    .decripe{
        p{
            text-align: center;
            line-height: 3.75rem;
        }
    }

    .button{
        margin:1.5rem auto;
         width:18%;
         display: flex;
         justify-content: center;
    }
}
</style>